@import 'compass';
@import 'compass/support';

@import 'setting';
@import 'mixin';

/* ======================================================================
 p_top
====================================================================== */
.p_top {


  .l_container_top {}

  .c_text {
    letter-spacing: 0.08em;
  }

  @include mq_max_768 {
    .contents_inner {
      padding: 0 5.333vw !important;
    }
  }

  // main_visual_top
  .main_visual_top {
    position: relative;
    z-index: 1;
    width: 100%;
    // height: 100vh;
    // background: url(../img/top/img_mv02.png) no-repeat center center;
    // min-height: 100vh;
    @include mq_min_769 {
      overflow: hidden;
      min-height: 700px;
    }
    @include mq_max_768 {
      overflow: hidden;
    }

    .slick-track {
      overflow: hidden;
      min-height: 700px;

    }
    .main_visual_slider {
      opacity: 0;
      transition: .5s;

    }

    .main_visual_slider_item {
      // height: 680px !important;
    }

    .main_visual_slider_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .main_visual_text {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 2;
      width: 20%;
      max-width: 256px;
      height: 435px;
      top: -150px;
      right: 90px;
      bottom: 0;
      left: 0;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .main_visual_banner_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 3;
      right: 0;
      bottom: 61px;
      left: 0;
      width: 100%;
      max-width: 1080px;
      margin: auto;

      .main_visual_banner {
        width: 32.4%;
        overflow: hidden;
        border-radius: 5px;

        &:nth-child(1) {
          .main_visual_banner_link {

            &::after {
              background: linear-gradient(to right, #5EABD5, #5EABD5 40%, #5EABD5 40%, rgba(255, 255, 255, 0) 100%);
            }
          }

          .banner_text_box {
            color: #fff;
          }

          &:hover {
            .main_visual_banner_link {
              &::after {
                width: 0;
                opacity: 0;
              }
            }

            .banner_text_box {
              color: #5EABD5;

              @include mq_max_768 {
                color: #fff
              }
            }
          }
        }

        &:nth-child(2) {
          .main_visual_banner_link {
            &::after {
              background: linear-gradient(to right, #4CA01F, #4CA01F 40%, #4CA01F 40%, rgba(255, 255, 255, 0) 100%);
            }
          }

          .banner_text_box {
            color: #fff;
          }

          &:hover {
            .main_visual_banner_link {
              &::after {
                width: 0;
                opacity: 0;
              }
            }

            .banner_text_box {
              color: #4CA01F;

              @include mq_max_768 {
                color: #fff
              }
            }
          }
        }

        &:nth-child(3) {
          .main_visual_banner_link {
            &::after {
              background: linear-gradient(to right, #CCC503, #CCC503 40%, #CCC503 40%, rgba(255, 255, 255, 0) 100%);
            }
          }

          .banner_text_box {
            color: #fff;
          }

          &:hover {
            .main_visual_banner_link {
              &::after {
                width: 0;
                opacity: 0;
              }
            }

            .banner_text_box {
              color: #CCC503;

              @include mq_max_768 {
                color: #fff
              }
            }
          }
        }
      }

      .main_visual_banner_link {
        display: block;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 2;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include transition(all .2s linear);
        }
      }

      .banner_text_box {
        display: block;
        position: absolute;
        z-index: 3;
        top: 20%;
        width: 100%;
        padding-left: 30px;
        line-height: 1.4;
        @include transition(all .2s linear);
      }

      .banner_text_top {
        display: block;
        width: 100%;
        font-size: 20px;
        font-family: $min_font;

        .u_pc {
          display: inline-block;
        }
      }

      .banner_text_bottom {
        display: block;
        margin-top: 1px;
        width: 100%;
        font-size: 13.3px;
        font-family: $min_font;
      }

      .main_visual_banner_img {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    @include mq_max_768 {
      height: 460px;

      .main_visual_slider_item {
        // height: 460px !important;
      }

      .main_visual_text {
        width: 54.387%;
        top: -18.3px;
        right: 47.9px;
        max-width: none;
        height: fit-content;
      }

      .main_visual_banner_box {
        position: absolute;
        bottom: 0px;

        .main_visual_banner {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33.33%;
          height: 56px;
          border-radius: 0;

          &:first-of-type {
            background-color: #5EABD5;
          }

          &:nth-of-type(2) {
            background-color: #4CA01F;
          }

          &:nth-of-type(3) {
            background-color: #CCC503;
          }
        }

        .banner_text_bottom {
          display: none;
        }

        .banner_text_box {
          position: static;
          padding-left: 0;
        }

        .main_visual_banner_img {
          display: none;
        }

        .main_visual_banner_link {
          &::after {
            content: none;
          }
        }

        .banner_text_top {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          line-height: 1.3;
          font-size: 4.267vw;

          .u_pc {
            display: none;
          }
        }
      }

      .main_visual_slider_img {
        height: 100%;
      }
    }
  }

  // preparation
  .preparation {
    position: relative;
    width: 1080px;
    margin: 100px auto 8px;

    .contents_inner {
      padding: 0;

      .c_text {
        letter-spacing: 0.08em;
      }
    }

    .preparation_top_title {
      margin-bottom: 35px;
    }

    .flow {
      margin: 67px auto 79px;

      .flow_inner {}

      .flow_contents {
        display: flex;
        align-items: center;
        width: 1080px;
        margin: auto;
      }

      .flow_item {
        position: relative;
        z-index: 2;
        width: 80px;
        height: 200px;
        margin-right: 10px;
        padding-top: 25px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;

        &.phase01 {
          background: #FDF7EC;
          border: 1px solid #DD935D;

          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: -10px;
            bottom: 0;
            width: 10px;
            height: 0;
            margin: auto;
            border-bottom: 6px solid #DD935D;
          }
        }

        &.phase02 {
          background: #EAF3D0;
          border: 1px solid #53B220;

          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: -10px;
            bottom: 0;
            width: 10px;
            height: 0;
            margin: auto;
            border-bottom: 6px solid #53B220;
          }
        }

        &.phase03 {
          background: #F9F9CB;
          border: 1px solid #D2D300;

          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: -10px;
            bottom: 0;
            width: 10px;
            height: 0;
            margin: auto;
            border-bottom: 6px solid #D2D300;
          }
        }

        &.phase04 {
          background: #FCE8E8;
          border: 1px solid #E6AEC2;
        }
      }

      .flow_num {
        position: relative;
        z-index: 1;
        margin: 0 auto 18px;
        padding-bottom: 6px;
        font-size: 16px;
        font-family: $min_font;
        text-align: center;
        width: 100%;

        &::after {
          content: "";
          display: block;
          width: 14px;
          position: absolute;
          z-index: 1;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          border-bottom: 1px solid;
        }
      }

      .flow_text {
        font-size: 16px;
        font-family: $min_font;
        letter-spacing: 0.18em;
        line-height: 1.4;
      }
    }

    .preparation_flower {
      position: absolute;
      top: 20px;
      right: -13px;
      width: 286px;
      height: 304px;
    }

    @include mq_max_768 {
      overflow-x: hidden;
      width: 100%;
      margin: calc(13.333vw + 56px) auto 0;

      .c_text {
        padding-right: 9.433vw;
      }

      .preparation_top_title {
        margin-bottom: 6.667vw;
        text-align: center;
      }

      .flow {
        margin: 9.467vw auto 10.933vw;

        .flow_inner {
          height: calc(48vw + 2px);
          margin-bottom: 7.333vw;
          padding-left: 5.533vw;
          overflow-y: scroll;
        }

        .flow_contents {
          display: flex;
          align-items: center;
          width: calc(((16vw * 10) + (6px*9)) + 5.333vw);
          margin: auto;
        }

        .flow_item {
          width: 60px;
          height:48vw;
          margin-right: 8px;
          padding-top: 5.067vw;

          &.phase01,
          &.phase02,
          &.phase03 {
            &::after {
              content: "";
              top: 20px;
              right: -10px;
              width: 10px;
            }
          }
        }

        .flow_num {
          margin: 0 auto 5.333vw;
          padding-bottom: 1.867vw;
          font-size: 3.733vw;

          &::after {
            width: 3.733vw;
          }
        }

        .flow_text {
          font-size: 3.833vw;
          line-height: 1.7;
        }
      }

      .preparation_flower {
        top: 43px;
        right: -73px;
        width: 246px;
        height: 290px;
      }

      .icon_point_scroll {
        margin: auto;
        width: 58.667vw;
        height: auto;
      }
    }
  }

  // type
  .type {
    margin: 133px auto 102px;

    .type_inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 1080px;
      margin: 0 auto -34px;

      .type_text_img {
        position: relative;
        top: -33px;
        left: -30px;
        width: 300px;
      }

      .type_text_box {
        padding-top: 107px;
        width: 700px;

        .type_title {
          margin-bottom: 34px;
        }
      }
    }

    .type_detail_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 50px;
      box-shadow: 0 0 30px #eaeaea;

      .type_detail_item {
        width: calc(100% / 3);
        background: #fff;
        @include transition(all .1s linear);

        &:nth-child(1) {
          border-bottom: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value01.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(2) {
          border-bottom: 1px solid #E3E3E3;
          border-right: 1px solid #E3E3E3;
          border-left: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value02.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(3) {
          border-bottom: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value03.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(4) {
          border-bottom: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value04.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(5) {
          border-bottom: 1px solid #E3E3E3;
          border-right: 1px solid #E3E3E3;
          border-left: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value05.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(6) {
          border-bottom: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value06.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(7) {
          .type_detail_value {
            background: url('../img/top/bg_type_value07.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(8) {
          border-right: 1px solid #E3E3E3;
          border-left: 1px solid #E3E3E3;

          .type_detail_value {
            background: url('../img/top/bg_type_value08.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:nth-child(9) {
          .type_detail_value {
            background: url('../img/top/bg_type_value09.jpg') no-repeat;
            background-size: cover;
          }
        }

        &:hover {
          transform: scale(1.05) rotate(0.0001deg);
          box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
          z-index: 10;

          .type_detail_title {
            text-decoration: none;
          }
        }
      }

      .type_detail_item_link {
        display: block;
        padding: 30px 25px 22px;
      }

      .type_detail_img {
        width: 100%;
        max-width: 300px;
        margin: auto;

        img {
          width: 100%;
        }
      }

      .type_detail_title {
        display: block;
        margin: 30px auto 20px;
        color: #5EABD5;
        font-size: 25px;
        font-weight: 400;
        font-family: $min_font;
        text-align: center;
        text-decoration: underline;
      }

      .type_detail_value {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 138px;
        height: 39px;
        margin: 20px auto 11px;
        font-size: 18px;
        letter-spacing: 0.06em;
        font-family: $min_font;
        text-align: center;

        .type_detail_num {
          padding-bottom: 3px;
          font-size: 25px;
          font-family: $min_font;
        }
      }

      .type_detail_text {
        color: #939393;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.08em;
        line-height: 1.82;
        padding: 0px 5px 0;
      }
    }

    @include mq_max_768 {
      margin: 26.667vw auto 21.333vw;

      .contents_inner {
        margin-top: 72px;
        padding: 0;
      }

      .type_inner {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 0 5.333vw;

        .type_text_img {
          position: absolute;
          z-index: 2;
          left: -5.333vw;
          top: auto;
          bottom: -65px;
          width: 49vw;

          img {
            width: 100%;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%);
          }
        }

        .type_text_box {
          position: relative;
          z-index: 3;
          padding-top: 0;
          width: 100%;
          margin-right: 0;
          margin-left: auto;
          padding-left: 20vw;
          //          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 30%, #fff 100%);

          .type_title {
            margin-bottom: 6vw;
          }
        }
      }

      .type_detail_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -5.333vw;
        margin-bottom: 10.1vw;
        box-shadow: 0 0 30px #eaeaea;

        .type_detail_item {
          width: 100%;

          &:nth-child(1) {
            border-bottom: 1px solid #E3E3E3;
          }

          &:nth-child(2) {
            border-bottom: 1px solid #E3E3E3;
            border-right: none;
            border-left: none;
          }

          &:nth-child(3) {
            border-bottom: 1px solid #E3E3E3;
          }

          &:nth-child(4) {
            border-bottom: 1px solid #E3E3E3;
          }

          &:nth-child(5) {
            border-bottom: 1px solid #E3E3E3;
            border-right: none;
            border-left: none;
          }

          &:nth-child(6) {
            border-bottom: 1px solid #E3E3E3;
          }

          &:nth-child(7) {
            border-bottom: 1px solid #E3E3E3;
          }

          &:nth-child(8) {
            border-right: none;
            border-bottom: 1px solid #E3E3E3;
            border-left: none;
          }

          &:hover {
            transform: scale(1.05) rotate(0.0001deg);
            box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
            z-index: 10;

            .type_detail_title {
              text-decoration: none;
            }
          }
        }

        .type_detail_item_link {
          padding: 5.233vw 5.133vw 5.333vw 5.333vw;
        }

        .type_detail_item_top {
          display: flex;
          flex-wrap: wrap;
        }

        .type_detail_item_top_text {
          width: calc(100% - 44.8vw);
          padding-top: 5.4vw;
          padding-left: 5.333vw;
        }

        .type_detail_img {
          width: 44.8vw;
          margin: 0;

          img {
            height: auto;
          }
        }

        .type_detail_title {
          display: block;
          margin: 0;
          font-size: 4.8vw;
          text-align: left;
        }

        .type_detail_value {
          width: 23.2vw;
          height: 7.467vw;
          margin: 4.533vw auto 0 0;
          font-size: 3.2vw;

          .type_detail_num {
            padding-bottom: 3px;
            font-size: 4.267vw;
          }
        }

        .type_detail_text {
          margin-top: 2.2vw;
          padding: 0;
          font-size: 3.72vw;
          line-height: 1.86;
          letter-spacing: 0.045em;
        }
      }
    }
  }

  // concept
  .concept {
    position: relative;
    z-index: 1;
    padding: 109px 0 80px;
    background: #F7F7F5;

    .concept_text_box {
      padding-bottom: 141px;
      background: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 100%);

      .concept_title {
        width: 783px;
        margin: 0 auto 75px;
        font-size: 3.4vw;
        text-align: left;
      }

      .concept_text {
        width: 783px;
        margin: auto;
        font-size: 18px;
        font-family: $min_font;
        line-height: 2.6;
      }

      .concept_text_A {
        display: block;
        margin-bottom: 26px;
      }

      .concept_text_B {
        display: block;
      }
    }

    .concept_contents {
      background: linear-gradient(#fff 0%, #fff 536px, #f7f7f5 536px, #f7f7f5 100%);
      @include mq_min_769 {      
        overflow: hidden;
      }
    }

    .concept_subcontents {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      padding-bottom: 115px;

      .concept_subcontents_img_left {
        width: 450px;

        img {
          width: 100%;
        }
      }

      .concept_subtext_box {
        padding-top: 89px;
        width: 550px;
      }

      .concept_subtitle {
        margin-bottom: 27px;
        font-size: 25px;
        font-weight: 400;
        font-family: "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝", YuMincho, "HG明朝E", "MS P明朝", "MS 明朝", serif;
        line-height: 1.6;
      }

      .concept_subtext {
        font-size: 16px;
        line-height: 1.8;
        letter-spacing: 0.08em;
      }

      .concept_subcontents_img_bottom {
        position: absolute;
        z-index: 1;
        top: 492px;
        right: -141px;
        width: 530px;
      }
    }

    @media screen and (min-width:1080px) {
      .concept_text_box {
        .concept_title {
          font-size: 38px;
        }
      }
    }

    @include mq_max_768 {
      overflow: hidden;
      padding: 20.333vw 0 10vw;

      .concept_text_box {
        padding-bottom: 80vw;
        background: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 100%);

        .concept_title {
          width: 100%;
          margin: 0 auto 8.633vw;
          font-size: 6.4vw;
          text-align: center;
          line-height: 1.8;
        }

        .concept_text {
          width: 100%;
          padding: 0 7.633vw;
          font-size: 4.267vw;

          .concept_text_A {
            margin-bottom: 7vw;
            line-height: 2.3;
          }

          .concept_text_B {
            line-height: 2.3;
          }
        }
      }

      .concept_contents {
        background: none // background: linear-gradient(#fff 0%, #fff 536px,#f7f7f5 536px,#f7f7f5 100%);
      }

      .concept_subcontents {
        flex-wrap: wrap;
        padding-top: 8vw;
        padding-bottom: 0;

        .concept_subcontents_img_left {
          margin-top: -65vw;
          width: 78.667vw;
          margin-left: -5.333vw;
        }

        .concept_subtext_box {
          width: 100%;
          padding-top: 8.767vw;
        }

        .concept_subtitle {
          margin-bottom: 4vw;
          font-size: 5.333vw;
          text-align: center;
          line-height: 1.8;
        }

        .concept_subtext {
          margin-bottom: 12.233vw;
          font-size: 3.733vw;
        }

        .concept_subcontents_img_bottom {
          position: relative;
          z-index: 1;
          top: auto;
          right: 0;
          left: auto;
          margin-top: auto;
          margin-left: auto;
          margin-right: -5.333vw;
          margin-bottom: -14vw;
          width: 81.333vw;

          img {
            width: 100%;
          }
        }
      }
    }

    //
    .pickup {
      margin-bottom: 40px;
      padding: 60px 0 60px;
      position: relative;
      z-index: 2;
      background: #fff;

      .contents_inner {
        padding: 0;
        width: 100%;
      }

      .pickup_title {
        display: block;
        margin-bottom: 31px;
        font-size: 25px;
        font-weight: 400;
        font-family: $min_font;
        text-align: center;
      }

      .pickup_banner_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 960px;
        margin: 30px auto 35px;

        .pickup_banner {
          width: 310px;
          margin-bottom: 15px;
        }

        .pickup_banner_link {
          display: block;
          position: relative;
          z-index: 3;
        }

        .pickup_banner_text {
          display: flex;
          align-items: center;
          position: absolute;
          z-index: 2;
          top: 3px;
          bottom: 0;
          right: 0;
          left: 25px;
          margin: auto;
          font-size: 18px;
          letter-spacing: 0.04em;
          font-family: "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝", YuMincho, "HG明朝E", "MS P明朝", "MS 明朝", serif;
          line-height: 1.4;
        }

        .pickup_banner_img {
          display: block;
          position: relative;
          z-index: 1;
          width: 100%;
        }
      }

      @include mq_max_768 {
        width: 100vw;
        margin-left: -5.3333vw;
        margin-top: -1.8vw;
        margin-bottom: 8vw;
        padding: 10.667vw 0;

        .pickup_title {
          margin-bottom: 8vw;
          font-size: 5.333vw;
        }

        .pickup_banner_box {
          display: flex;
          width: 100%;
          margin: 30px auto 40px;

          .pickup_banner {
            width: 94%;
            margin: 0 auto 5px;
          }

          .pickup_banner_link {
            height: 18.667vw;
            overflow: hidden;
          }

          .pickup_banner_text {
            top: 4px;
            left: 20px;
            margin: auto;
            font-size: 4.267vw;
          }

          .pickup_banner_img {
            position: absolute;
            top: -3vw;
          }
        }
      }
    }


    .voice {
      position: relative;
      z-index: 2;
      padding: 60px 0 60px;
      background: #fff;

      .contents_inner {
        width: 100%;
      }

      .voice_title {
        display: block;
        font-size: 25px;
        font-weight: 400;
        font-family: $min_font;
        text-align: center;
      }

      .voice_slider_box {
        margin: 0 auto;
        width: 960px;

        .voice_slider {
          margin-bottom: 100px;
        }

        .slide-arrow {
          position: absolute;
          top: 50%;
          margin-top: -15px;
          z-index: 999;
        }

        .prev-arrow {
          background-image: url(../img/top/btn_voice_slider_prev.svg);
          background-size: 100%;
          background-position: top left;
          left: 45px;
          top: 162px;
          width: 40px;
          height: 40px;
        }

        .next-arrow {
          background-image: url(../img/top/btn_voice_slider_next.svg);
          background-size: 100%;
          background-position: top left;
          right: 44px;
          top: 162px;
          width: 40px;
          height: 40px;
        }

        .voice_slider_item {
          padding-top: 40px;
          position: relative;
          margin: 0 25px;

          &.slick-current {
            .voice_slider_inner {
              background: #ECF7FD;
            }

            .voice_slider_address {
              opacity: 1;
            }
          }
        }

        .voice_slider_link {
          display: block;
        }

        .voice_slider_inner {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          position: relative;
          margin: auto;
          padding: 30px;
          width: 750px;
          background: #F7F6F4;
        }

        .voice_slider_img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 200px;
          background: #fff;
        }

        .voice_slider_text_box {
          width: 460px;
        }

        .voice_slider_title {
          padding-right: 60px;
          font-size: 18px;
          font-weight: 400;
          font-family: $min_font;
          line-height: 1.5;
        }

        .slick-dots li {
          width: 15px;
          height: 15px;
        }

        .slick-dots li button {
          width: 15px;
          height: 15px;
        }

        .voice_status {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 16px 0 15px;

          .voice_status_name {
            margin-right: 14px;
            padding-right: 14px;
            border-right: 1px solid;
            font-size: 14px;
          }

          .voice_status_place {
            font-size: 14px;
          }

          .voice_status_place_detail {}
        }

        .voice_slider_text {
          font-size: 14px;
          line-height: 1.7;
        }

        .voice_slider_address {
          background-image: url('../img/top/bg_voice_place.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top left;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 6px;
          height: 100px;
          width: 100px;
          opacity: 0;
          @include transition(all .2s linear);

          .voice_slider_address_prefecture {
            color: #fff;
            font-size: 12px;
            line-height: 1.5;
            text-align: center;
          }

          .voice_status_address_city {
            font-size: 16px;
          }
        }
      }

      .slick-dots {
        bottom: -50px;
        text-align: center;

        li {
          display: inline-block;
          margin: 0 10px;

          button {
            position: relative;
            text-indent: -9999px;

            &:before {
              content: '・';
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
              width: 15px;
              height: 15px;
              background: #EFEADF;
              border-radius: 50%;
              opacity: 0.8;
            }
          }

          &.slick-active {
            button:before {
              content: '';
              width: 20px;
              height: 20px;
              background: url('../img/top/icon_voice_slider_dots.svg') no-repeat;
            }
          }
        }
      }

      @include mq_max_768 {
        width: 100vw;
        margin-left: -5.3333vw;
        margin-bottom: 6.2vw;
        padding: 10.667vw 0 9.267vw;

        .voice_title {
          margin-bottom: 2.6vw;
          font-size: 5.333vw;
        }

        .voice_slider_box {
          margin: 0 auto;
          width: 100%;

          .voice_slider {
            margin-bottom: 26.667vw;

            .slick-list {
              overflow: inherit;
            }
          }

          .slide-arrow {
            position: absolute;
            z-index: 999;
            top: auto;
            bottom: -17vw;
            width: 10.667vw;
            height: 10.667vw;
          }

          .prev-arrow {
            left: 4.667vw;
            bottom: -56px;
          }

          .next-arrow {
            right: 4.667vw;
            bottom: -56px;
          }

          .voice_slider_item {
            padding-top: 5.333vw;
            margin: 0 2.6665vw;
          }

          .voice_slider_inner {
            display: block;
            margin: auto;
            padding: 5.3333vw;
            width: 100%;
            min-height: 64.4vh;
          }

          .voice_slider_img {
            width: 100%;
            height: auto;
            margin-bottom: 5.3333vw;
            background: #fff;

            img {
              width: 32vw;
              margin: auto;
            }
          }

          .voice_slider_text_box {
            width: 100%;
          }

          .voice_slider_title {
            padding-right: 0;
            font-size: 4.367vw;
            line-height: 1.3;
            letter-spacing: 0.02em;
          }

          .voice_status {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 20px 0 14px;

            .voice_status_name {
              margin-right: 3.733vw;
              padding-right: 3.733vw;
              border-right: 1px solid;
              font-size: 3.733vw;
            }

            .voice_status_place {
              font-size: 3.733vw;
            }

            .voice_status_place_detail {}
          }

          .voice_slider_text {
            font-size: 3.9vw;
            letter-spacing: 0;
            line-height: 1.6;
          }

          .voice_slider_address {
            top: -3px;
            right: -10px;
            height: 86px;
            width: 86px;
            @include transition(all .2s linear);
            z-index: 10;

            .voice_slider_address_prefecture {
              font-size: 2.667vw;
            }

            .voice_status_address_city {
              font-size: 3.733vw;
            }
          }
        }

        .slick-dots {
          bottom: -12vw;

          li {
            margin: 0 2vw;

            button {
              &:before {
                content: '';
                width: 4vw;
                height: 4vw;
              }
            }

            &.slick-active {
              button:before {
                content: '';
                width: 5.333vw;
                height: 5.333vw;
                background-size: 100%;
              }
            }
          }
        }
      }
    }

    .concept_flower {
      display: block;
      position: absolute;
      z-index: 10;

      &.position_top_left {
        top: -2.2vw;
        left: 0;
        width: 16.984vw;
        max-width: 232px;

        img {
          width: 100%;
        }
      }

      &.position_top_right {
        top: 0;
        right: 0;
        width: 19.619vw;
        max-width: 268px;

        img {
          width: 100%;
        }
      }

      &.position_center_left {
        top: 120px;
        left: -60px;
        width: 5.637vw;
        max-width: 77px;

        img {
          width: 100%;
        }
      }

      &.position_center_right {
        top: -66px;
        right: -37px;
        max-width: 4.758vw;

        img {
          width: 100%;
        }
      }

      &.position_bottom {
        bottom: -4px;
        left: 8px;
        width: 233px;
        max-width: 282px;

        img {
          width: 100%;
        }
      }

      @include mq_max_768 {
        &.position_top_left {
          top: -6.133vw;
          width: 30.4vw;
        }

        &.position_top_right {
          width: 27.2vw;
        }

        &.position_center_left {
          top: -66.5vw;
          right: 10vw;
          left: auto;
          width: 18.667vw;
          transform: rotate(-16deg);
        }

        &.position_center_right {
          top: -18.7vw;
          right: 11.067vw;
          width: 12.933vw;
          max-width: 13vw;
        }

        &.position_bottom {
          right: -16px;
          bottom: 0px;
          left: auto;
          width: 32.167vw;
          transform: scale(-1, 1) rotate(0.0001deg);
        }
      }
    }
  }

  .news {
    padding: 80px 0 0;

    .news_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .news_contents {
      width: 494px;
      position: relative;
    }

    .news_title {
      margin-bottom: 23px;
      font-size: 18px;
      font-weight: 400;
      font-family: $min_font;
      line-height: 1.4;
    }

    .news_list {
      margin: 0 -8px;
    }

    .news_item {
      margin-bottom: 8px;
      padding: 8px;
    }

    .news_link {
      display: flex;
      justify-content: space-between;
    }

    .news_img {

      img {
        object-fit: cover;
        object-position: center top;
        width: 150px;
        height: 100px;
      }
    }

    .news_text_box {
      width: 318px;
    }

    .news_status_box {
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin: 16px 0 16px;

      .news_date {
        color: #939393;
        font-size: 14px;
      }

      .news_category {
        position: absolute;
        left: 111px;
        top: -4px;
        padding: 4px;
        font-size: 12px;

        &.category_type01 {
          background: #ECF7FD;
        }

        &.category_type02 {
          background: #EAF3D0;
        }

        &.category_type03 {
          background: #F9F9CB;
        }

        &.category_type04 {
          background: #FDF1EC;
        }

      }
    }

    .news_text {
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: 0.08em;
      height: 44px;
      position: relative;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .news_archive_btn {
      display: block;
      text-align: right;
      padding-right: 12px;
      position: absolute;
      right: -11px;
      bottom: -31px;


      .news_archive_link {
        position: relative;
        z-index: 1;
        margin-top: 0px;
        margin-right: 34px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝", YuMincho, "HG明朝E", "MS P明朝", "MS 明朝", serif;

        &::before {
          content: "";
          display: block;
          position: absolute;
          z-index: 2;
          top: 0;
          right: -37px;
          bottom: 0;
          width: 29px;
          height: 6px;
          margin: auto;
          background: url(../img/common/icon_btn_arrow.svg) no-repeat;
          transition: all 0.16s linear;
          z-index: 3;
          @include transition(all .2s linear);
        }

        &:hover {
          color: #5EABD5;

          &::before {
            content: "";
            right: -45px;
          }
        }
      }
    }

    @include mq_max_768 {
      padding: 16.5vw 0 0;

      .news_container {
        display: block;
      }

      .news_contents {
        margin: 0 -4px;
        margin-bottom: 15vw;
        width: 100%;

        &:first-of-type {
          margin-bottom: 22vw;
        }
      }

      .news_title {
        margin-bottom: 4.2333vw;
        font-size: 4.267vw;
      }

      .news_list {
        margin: 0 -4px;
      }

      .news_item {
        margin-bottom: 14px;
        padding: 4px;
      }

      .news_img {
        img {
          width: 90px;
          height: 60px;
        }
      }

      .news_text_box {
        width: calc(100% - 24vw);
        padding-left: 4vw;
      }

      .news_status_box {
        display: block;
        margin: 0 0 1vw;

        .news_date {
          display: block;
          width: 100%;
          font-size: 13.3px;
          letter-spacing: 0em;
          font-weight: 300;
        }

        .news_category {
          display: inline-block;
          margin: 3.6vw 0 0;
          padding: 4px;
          font-size: 12px;
          position: relative;
          left: 0;
        }
      }

      .news_text {
        font-size: 3.733vw;
      }

      .news_archive_btn {
        position: absolute;
        bottom: -40px;
        right: 0;
        display: block;
        text-align: right;
        margin-top: -22px;

        .news_archive_link {
          margin: 0;
          padding-right: 37.8px;
          font-size: 3.5vw;
          letter-spacing: -0.2px;

          &::before {
            right: 0;
          }

          &:hover {
            &::before {
              content: "";
              right: 0;
            }
          }
        }
      }
    }
  }

  // news

}